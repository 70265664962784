.Container {

  position: relative;
  .About {
  //  position:absolute; top:0px; right:0px; bottom:0px; left:0px;
    flex: 1;
    flex-direction: column;
    color: #ffffff;
  //  //background-color: #1f1f1f;
  //
    border-right-color: white;
    border-right-width: 1vh;
    height: 100vh;
    display: flex;
    //font-family: 'Dancing Script', arial, serif;
    //animation: color-change 5s infinite;
    //@keyframes color-change {
    //  0% { color: black; }
    //  50% { color: white; }
    //  100% { color: black; }
    //}
    .FullName {
      margin-top: 20vh;
      .typewriter {
        font-family: Courier, monospace;
        display: inline-block;
      }

      .typewriter-text {
        display: inline-block;
        overflow: hidden;
        letter-spacing: 2px;
        animation: typing 1s steps(30, end), blink .75s step-end infinite;
        white-space: nowrap;
        font-size: 80px;
        font-weight: 700;
        border-right: 4px solid orange;
        box-sizing: border-box;
      }
    }
  //
    .SocialNetworks {
      margin-top: 50px;
      img {
        height: 10vh;
        margin-right: 50px;
      }
    }
  }

  .BackgroundImage {
    opacity: myVar/1;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../../assets/2494730141_f2b4cb38b4_b.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100vw 100vh;
    filter: blur(10px);
    -webkit-filter: blur(10px);
    z-index: -1;
    animation: myVar 3s  infinite;
  }

  @keyframes myVar {
    0% {
      --one:0.8;
      opacity: var(--one);
    }
    50% {
      opacity: var(--one);
      --one: 1;
    }

    100% {
      opacity: var(--one);
      --one: 0.8;
    }
  }
}
.floating {
  -webkit-animation-name: Floatingx;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-name: Floating;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@-webkit-keyframes Floatingx {
  from {-webkit-transform:translate(0, 0px);}
  65% {-webkit-transform:translate(0, 15px);}
  to {-webkit-transform: translate(0, -0px);}
}

@-moz-keyframes Floating {
  from {-moz-transform:translate(0, 0px);}
  65% {-moz-transform:translate(0, 15px);}
  to {-moz-transform: translate(0, -0px);}
}



@keyframes typing {
  from {
    width: 0%
  }
  to {
    width: 100%
  }
}

@keyframes blink {
  from, to {
    border-color: transparent
  }
  50% {
    border-color: orange;
  }
}